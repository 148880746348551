import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import BasicTextField from "./BasicTextField/BasicTextField";
import BasicCheckbox from "./BasicCheckbox/BasicCheckbox";
import SelectField from "./SelectField/SelectField";
import BasicTextArea from "./BasicTextArea/BasicTextArea";
import DateField from "../SharedComponents/DateField/DateField";
import SearchSelectField from "../SharedComponents/SearchSelectField";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import {
  validateAllPhone,
  formatPhoneNumbers,
  validateEmail,
  validateNumbersOnly,
  validateNumberFloatOnly,
} from "../../Common/Helper";
import BasicButton from "./BasicButton/BasicButton";
import "./shared.scss";
import RichTextEditor from "./RichTextEditor/RichTextEditor";

class TableInlineEdits extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  inlineValidations(method, value) {
    switch (method) {
      case "validateAllPhone":
        return validateAllPhone(value);
        break;
      case "validateEmail":
        return validateEmail(value);
        break;
      case "validateNumbersOnly":
        return validateNumbersOnly(value);
        break;
      case "validateNumberFloatOnly":
        return validateNumberFloatOnly(value);
        break;
      default:
        true;
    }
  }

  pickInlineEdits(inputType, inputValue, index, nodeName, selectOptions) {
    switch (inputType) {
      case "richtexteditor":
        return (
        <RichTextEditor
        id={"txt-" + nodeName + "-" + index}
        className={"discription-editor"}
        value={inputValue}
        richtextflag={true}
        width={this.props?.width}
        onChange={(content) => this.props?.handleInlineEditChange(content, index, nodeName)}
        />
        );
        break;
      case "text":
        return (
          <BasicTextField
            id={"txt-" + nodeName + "-" + index}
            width={this.props?.width}
            value={inputValue}
            placeholder={this.props?.placeholder ? this.props?.placeholder : ""}
            fieldValid={
              this.props?.validateMethod && inputValue
                ? !this.inlineValidations(
                    this.props?.validateMethod,
                    inputValue
                  )
                : false
            }
            isMandatory={this.props.isMandatory}
            showMandatory={this.props.showMandatory}
            inValidInput={
              this.props?.validateMessage ? this.props?.validateMessage : null
            }
            onChange={(e) => {
              if (this.props?.validateMethod === "validateAllPhone") {
                this.props.handleInlineEditChange(
                  e.target.value.length > 0
                    ? formatPhoneNumbers(e.target.value)
                    : null,
                  index,
                  nodeName
                );
              } else if (
                (this.props?.validateMethod === "validateNumbersOnly" ||
                  this.props?.validateMethod === "validateNumberFloatOnly") &&
                e.target.value.length > 0
              ) {
                this.inlineValidations(
                  this.props?.validateMethod,
                  e.target.value
                ) &&
                  this.props.handleInlineEditChange(
                    e.target.value.length > 0 ? e.target.value : null,
                    index,
                    nodeName
                  );
              } else {
                this.props.handleInlineEditChange(
                  e.target.value.length > 0 ? e.target.value : null,
                  index,
                  nodeName
                );
              }
            }}
          />
        );
        break;
      case "number":
        return (
          <BasicTextField
            id={"number-" + nodeName + "-" + index}
            width={this.props?.width}
            value={inputValue}
            type="number"
            placeholder={this.props?.placeholder ? this.props?.placeholder : ""}
            isMandatory={this.props.isMandatory}
            showMandatory={this.props.showMandatory}
            inValidInput={
              this.props?.validateMessage ? this.props?.validateMessage : null
            }
            onChange={(e) => {
              this.props.handleInlineEditChange(
                e.target?.value?.length > 0 ? e.target.value : null,
                index,
                nodeName
              );
            }}
          />
        );
        break;
      case "textarea":
        return (
          <BasicTextArea
            id={"txtarea-" + nodeName + "-" + index}
            width={this.props?.width}
            value={inputValue}
            rows={2}
            showAsLabel={false}
            onChange={(e) =>
              this.props.handleInlineEditChange(
                e.target.value.length > 0 ? e.target.value : null,
                index,
                nodeName
              )
            }
            InputProps={
              this.props?.icon
                ? {
                    endAdornment: (
                      <Tooltip title={this.props?.tooltip} placement="top">
                        <InputAdornment position="end">
                          <BasicButton
                            icon={this.props?.icon}
                            className="textarea-inline-icon"
                            text=" "
                            variant="contained"
                            onClick={this.props?.inlineIconClick}
                          />
                        </InputAdornment>
                      </Tooltip>
                    ),
                  }
                : null
            }
          />
        );
        break;
      case "checkbox":
        return inputValue !== null ? (
          <BasicCheckbox
            id={"cb-" + nodeName + "-" + index}
            checked={
              this.props?.newIsActive &&
              (this.props?.nodeName === "is_active" ||
                this.props?.nodeName === "is_cc_active")
                ? true
                : inputValue
            }
            className={"cb-" + nodeName + "-" + index}
            //onChange={(e) => this.props.addFilterChangeHandler((e.target.checked ? "1": "0"))}
            width={this.props?.width}
            disabled={
              this.props?.fieldDisabled ||
              (this.props?.newIsActive &&
                (this.props?.nodeName === "is_active" ||
                  this.props?.nodeName === "is_cc_active"))
            }
            onChange={(e) =>
              this.props.handleInlineEditChange(
                e.target.checked ? true : false,
                index,
                nodeName
              )
            }
          />
        ) : (
          <> </>
        );
        break;
      case "select":
        return (
          <SelectField
            id={"sel-" + nodeName + "-" + index}
            size="small"
            label={name}
            width={this.props?.width}
            placeHolderText={
              this.props?.placeholder ? this.props?.placeholder : ""
            }
            value={inputValue || ""}
            options={selectOptions}
            isMandatory={this.props.isMandatory || false}
            showMandatory={this.props.showMandatory || false}
            inValidInput={
              this.props?.validateMessage ? this.props?.validateMessage : null
            }
            disabled={this.props?.fieldDisabled}
            onChange={(e) =>
              this.props.handleInlineEditChange(e.target.value, index, nodeName)
            }
          />
        );
        break;
      case "datefield":
        return (
          <DateField
            id={"date-" + nodeName + "-" + index}
            size="small"
            label={name}
            width={this.props?.width}
            value={inputValue || ""}
            onChange={(e) =>
              this.props.handleInlineEditChange(
                e.target.value.length > 0 ? e.target.value : null,
                index,
                nodeName
              )
            }
          />
        );
        break;
      case "searchselect":
        // return <SearchSelectField
        //   id={"roles-select" + nodeName + "-" + index}
        //   size="small"
        //   fieldWidth={this.props?.width}
        //   multiple={false}
        //   value={inputValue || null}
        //   options={selectOptions}
        //   onChange={(e, value) => this.props.handleInlineEditChange(value, index, nodeName)}
        // />;
        return (
          <SearchSelectField
            id={"roles-select" + nodeName + "-" + index}
            size="small"
            width={this.props?.width}
            placeholder={this.props?.placeholder ? this.props?.placeholder : ""}
            fieldWidth={this.props?.width}
            multiple={false}
            detail_selected={inputValue || null}
            options={selectOptions}
            searchSelect={true}
            isMandatory={this.props.isMandatory || false}
            showMandatory={this.props.showMandatory || false}
            searchText={(e) =>
              this.props?.searchString(e.target.value, this.props.nodeName)
            }
            valueSelected={(e, value) =>
              this.props.handleInlineEditChange(value, index, nodeName)
            }
          />
        );
        break;
      case "button":
        return (
          <div id="inline-add-color" className="buttonField-label">
            <BasicButton
              variant="contained"
              id={"roles-select" + nodeName + "-" + index}
              size="small"
              fieldWidth={this.props?.width}
              value={inputValue || null}
              text={this.props?.text ? this.props?.text : " "}
              icon={this.props?.icon === "" ? "" : this.props?.icon}
              onClick={(e) =>
                this.props.handleOnButtonClick(e.target.value, index, nodeName)
              }
            />
          </div>
        );
        break;
      case "label":
        return this.props?.width ? (
          <div className="d-inline" style={{ width: `${this.props?.width}` }}>
            <BasicLabel text={inputValue || ""} />
          </div>
        ) : (
          <BasicLabel text={inputValue || ""} />
        );
      default:
        return (
          <div
            style={{ width: this.props?.width ? this.props?.width : "" }}
          ></div>
        );
    }
  }

  render() {
    return (
      <>
        {this.pickInlineEdits(
          this.props?.inputType,
          this.props?.inputValue,
          this.props?.index,
          this.props?.nodeName,
          this.props?.selectOptions
        )}
      </>
    );
  }
}

export default TableInlineEdits;
